import React from 'react'
import ShowsList from '../../components/ShowsList';
import ShowsCategory from '../../components/ShowsCategorySidebar';
import './shows.css'

const Shows = () => {
  return (
    <div className='shows-container'>
        <div className='flex--1'><ShowsCategory /></div>
        <div className='flex--2'><ShowsList /></div>
    </div>
  )
}

export default Shows
