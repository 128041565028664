import React from 'react'
import ShowsCard from '../ShowsCard/index';
import './showsList.css'

const showsData = [
    {
        heading:"Pepsico Show 1",
        category:"My Category 1",
        episodeCount:1
    },
    {
        heading:"Pepsico Show 2",
        category:"My Category 1",
        episodeCount:1
    },
    {
        heading:"Pepsico Show 3",
        category:"My Category 1",
        episodeCount:1
    },
    {
        heading:"Pepsico Show 4",
        category:"My Category 2",
        episodeCount:1
    },
    {
        heading:"Pepsico Show 5",
        category:"My Category 2",
        episodeCount:1
    },
    {
        heading:"Pepsico Show 6",
        category:"My Category 3",
        episodeCount:1
    },
    {
        heading:"Pepsico Show 7",
        category:"My Category 4",
        episodeCount:1
    }
]

const ShowsList = () => {
  return (
    <div className='shows-list__container'>
        {
            showsData.map((show,id)=>
                <ShowsCard 
                    id={id}
                    heading={show.heading} 
                    category={show.category}
                    episodeCount={show.episodeCount}/>
            )
        }
    </div>
  )
}

export default ShowsList
