import React from 'react'
import { Redirect,useHistory } from 'react-router-dom'
import './showsCard.css'


const ShowsCard = ({heading,category,episodeCount,id}) => {
  const history = useHistory();
  const redirectToEpisodeHandle=()=>{
    console.log("clicked");
    history.push(`/shows/${id}`);
  }

  return (
    <div className='shows-card__outer-container'
        onClick={redirectToEpisodeHandle}>
        <div className='shows-img'>
            {/* Get the img URL here */}
        </div>
        <div className='shows-heading text-align--center'>{heading}</div>
        <div className='shows-category'>{category}</div>
        <div className='episode-count text-align--center'>{episodeCount} episodes</div>
    </div>
  )
}

export default ShowsCard
