import React from 'react'
import './showsCategorySidebar.css'

const categoryData = ["Category 1","Category 2","Category 3","Category 4",]

const ShowsCategory = () => {
  return (
    <div className='shows-category__container'>
      <div className='heading'>
        PODCASTS AVAILABLE FROM
      </div>
      <div className='category-list'>
        {categoryData.map(category=>(
            <div className='list-items'>
                <div className='bullet-icon'>
                    o
                </div>
                <div className='category-name'>
                    {category}
                </div>
            </div>
        ))}
      </div>
    </div>
  )
}

export default ShowsCategory
