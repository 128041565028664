import React from 'react'
import './showDetailsSidebar.css';

const ShowDetailsSidebar = () => {
  return (
    <div className='show-details__container'>
      <div className='image'></div>
      <div className='title'>Podcast/Show title</div>
      <div className='description'> This line gives the description of a given podcast/show. Lorem Ipsum is the dummy text here</div>
    </div>
  )
}

export default ShowDetailsSidebar
