import * as appRoutes from "./routeConstants/appRoutes";
import TopNavBar from "../shared/components/TopNavBar";
import React from "react";
import { Route, Switch,Redirect,withRouter } from "react-router-dom";
import Home from "../Home";
import Locked from "../Locked";
import Profile from "../Profile";
import { oktaConfig } from "./../config/oktaConfig";
import Login from "../views1/ClientLogin/Login";
import ListPodcasts from "../views1/ListPodcasts";
import Shows from "../views/Shows";
import Episodes from "../views/Episodes";
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { useHistory } from 'react-router-dom';

const oktaAuth = new OktaAuth(oktaConfig);

const App = () => {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <TopNavBar/>
      <Route path='/' exact={true} component={Home}/>
      <SecureRoute path='/shows' exact={true} component={Shows}/>
      <SecureRoute path='/shows/:showId' exact={true} component={Episodes}/>
      <Route path='/login/callback' exact={true} component={LoginCallback}/>
    </Security>
  );
};

export default withRouter(App);