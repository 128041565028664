import React from 'react'

const EpisodesList = () => {
  return (
    <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
      <div style={{padding:"20px",height:"200px",width:"650px",alignItems:"center",color:"#dcdcdc",boxShadow:"0.5px 1px 12px #cccccc, -0.2px -0.2px 12px #cccccc",borderRadius:"10px",marginBottom:"32px"}}>
        EPISODE 1
      </div>
      <div style={{padding:"20px",height:"200px",width:"650px",alignItems:"center",color:"#dcdcdc",boxShadow:"0.5px 1px 12px #cccccc, -0.2px -0.2px 12px #cccccc",borderRadius:"10px",marginBottom:"32px"}}>
        EPISODE 2
      </div>
    </div>
  )
}

export default EpisodesList
