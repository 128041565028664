import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import Login from "./views1/ClientLogin/Login";
import ListPodcasts from "./views1/ListPodcasts";
import {Redirect} from 'react-router-dom';
import {Spinner} from './shared/components/Spinner/Spinner'

const Home = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const loginHandle = () => oktaAuth.signInWithRedirect();
  const logoutHandle = () => oktaAuth.signOut();
  console.log(oktaAuth);
  console.log(authState);
  console.log(oktaAuth.signInWithRedirect);
  if (!authState) {
    return <div className="spinner__container">
				<Spinner />
      </div>;
  } else if (!authState.isAuthenticated) {
    return (
      <Login loginHandle={loginHandle}/>
    );
  } else {
    return (
      <>
      {/* <div style={{position:"fixed",right:"16px",top:"16px",zIndex:"12"}}>
        <button style={{border:"none",outline:"none",padding:"7.5px 12px",backgroundColor:"#ffffff",borderRadius:"15px"}} onClick={logoutHandle}>Logout</button>
      </div>
      <ListPodcasts /> */}
      <Redirect push to="/shows" />
      </>
      );
  }
};
export default Home;
