const REACT_APP_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const REACT_APP_OKTA_DOMAIN = process.env.REACT_APP_OKTA_DOMAIN;
const REACT_APP_PORT = process.env.REACT_APP_PORT;
const REACT_APP_REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;
export const oktaConfig = {
	clientId: `${REACT_APP_CLIENT_ID}`,
	issuer: `https://${REACT_APP_OKTA_DOMAIN}/oauth2/default`,
	redirectUri: `${REACT_APP_REDIRECT_URI}/login/callback`,
	scopes: ['openid', 'profile', 'email', 'offline_access'],
    pkce: true,
};
